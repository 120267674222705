import { render, staticRenderFns } from "./InsuranceClaims.vue?vue&type=template&id=4b60f10c&"
import script from "./InsuranceClaims.vue?vue&type=script&lang=js&"
export * from "./InsuranceClaims.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports